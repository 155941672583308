export const truckTypes = [
  { label: 'LCV', value: 'LCV', truck_id: '1' },
  { label: 'Truck', value: 'Truck', truck_id: '2' },
  { label: 'Container', value: 'Container', truck_id: '3' },
  { label: 'Trailer', value: 'Trailer', truck_id: '4' },
  { label: 'Hyva', value: 'Hyva', truck_id: '5' },
];

export const typeTrucks = [
  {
    label: '32 Feet Single Axle',
    value: '32 Feet Single Axle',
    typeId: '1',
  },
  {
    label: '32 Feet Single Axle High Cube',
    value: '32 Feet Single Axle High Cube',
    typeId: '2',
  },
  {
    label: '32 Feet Multi Axle',
    value: '32 Feet Multi Axle',
    typeId: '3',
  },
  {
    label: '32 Feet Multi Axle High Cube',
    value: '32 Feet Multi Axle High Cube',
    typeId: '4',
  },
  {
    label: '32 Feet Triple Axle',
    value: '32 Feet Triple Axle',
    typeId: '5',
  },
  {
    label: '20 Feet Closed',
    value: '20 Feet Closed',
    typeId: '6',
  },
  {
    label: '22 Feet Closed',
    value: '22 Feet Closed',
    typeId: '7',
  },
  {
    label: '24 Feet Closed',
    value: '24 Feet Closed',
    typeId: '8',
  },
  {
    label: '17 Feet Open',
    value: '17 Feet Open',
    typeId: '9',
  },
  {
    label: '20 Feet Open',
    value: '20 Feet Open',
    typeId: '10',
  },
  {
    label: '22 Feet Open',
    value: '22 Feet Open',
    typeId: '11',
  },
  {
    label: '24 Feet Open',
    value: '24 Feet Open',
    typeId: '12',
  },
  {
    label: '10 Whi Open',
    value: '10 Whi Open',
    typeId: '13',
  },
  {
    label: '12 Whi Open',
    value: '12 Whi Open',
    typeId: '14',
  },
  {
    label: '14 Whi Open',
    value: '14 Whi Open',
    typeId: '15',
  },
];
