import { Domestic, International } from "../components";

export const ordersTabs = [
    {
      title: 'Domestic',
      id: 'domestic',
      panel: <Domestic />,
    },
    {
      title: 'International',
      id: 'internationl',
      panel: <International />,
    },
  ];
  